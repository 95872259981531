/** @jsx jsx */
import { AspectRatio, jsx } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import ProjectHero from '../components/ProjectHero';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProjectTeaser from '../components/ProjectTeaser';
import Layout from '../global/Layout';

const IndexPage = ({ data }) => (
  <Layout>
    <Header />
    <ProjectHero data={data.sanityProject} />

    {/* {data.allsanityProject.edges.map(({node}, index) => {
      return <ProjectTeaser title={node.title} subtitle={node.category } variant={index % 2 === 0 ? 'titleAbove': 'titleBelow'}
      mainImage={node.mainImage.asset.fixed.src}
      featuredImage={node.featuredImage.asset.fixed.src} />

    })}
     */}

    <Footer />
  </Layout>
);

export const query = graphql`
  query Project {
    sanityProject {
      title

      category
      featuredImage {
        asset {
          fixed(width: 840) {
            base64
            srcWebp
            srcSetWebp
            src
            srcSet
          }
        }
      }
      mainImage {
        asset {
          fixed(height: 348, width: 408) {
            base64
            srcWebp
            srcSetWebp
            src
            srcSet
          }
        }
      }
    }
  }
`;

export default IndexPage;
